import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import PageWrapper from "components/PageWrapper";
import RichText from "components/RichText";
import SolidCircularButton from "components/SolidCircularButton";
import log from "utils/log";
import * as siteTheme from "theme";
import * as coreTheme from "@bit/cpinnix.verious.theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    heroImage: contentfulAsset(
      contentful_id: { eq: "3JF49xs7dIfiiEiCVuAE4n" }
    ) {
      id
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    foster: contentfulText(contentful_id: { eq: "73HDMNVYx59IOw7dwcA2qK" }) {
      id
      text {
        raw
      }
    }
  }
`;

function present(data) {
  const seo = {
    title: "Home",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    foster: data?.foster?.text,
    heroImage: data?.heroImage?.fluid,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const { foster, seo, heroImage } = present(data);

  return (
    <PageWrapper seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>Foster</h1>
              <div className={heroClasses.heroContent}>
                <RichText
                  json={foster}
                  theme={{
                    title: {
                      color: siteTheme.heading.primary,
                      fontFamily: siteTheme.font.primary,
                    },
                  }}
                />
                <a
                  href="https://forms.gle/iqZqaZ4J53F6UV1K9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SolidCircularButton
                    theme={{
                      backgroundColor: siteTheme.button.primary,
                      color: coreTheme.white,
                    }}
                  >
                    Fill out the form
                  </SolidCircularButton>
                </a>
              </div>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage fluid={heroImage} />
          </div>
        </div>
      </div>
      {/* <---- Hero */}
    </PageWrapper>
  );
}
